<template>
  <div class="content-element content-element--padded-bottom">
    <h1 class="h1-as-h2">Aktuelles</h1>
    <posts :showMore="false" :posts="posts"></posts>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Posts from '../components/Posts.vue';
import { Actions } from '../store';

export default defineComponent({
  name: 'Post Overview',
  components: {
    Posts,
  },
  mounted() {
    this.$store.dispatch(Actions.LoadAllPosts);
  },
  computed: {
    posts() {
      return this.$store.state.posts;
    },
  },
});
</script>
